@import '../../scss/colors.scss';

.container {
	text-align: center;
	margin-top: 75px;
	margin-bottom: 50px;
	> h1 {
		color: $blue-color;
		font-size: 50px;
	}
}
