.loading_wrapper {
	min-height: 80vh;
	max-width: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 0 auto;

	> img {
		width: 100%;
	}
	@media (min-width: 900px) {
		min-height: 100vh;
	}
}
