@import '../../scss/colors.scss';
@import '../../scss/mixins.scss';

.container {
	display: flex;
	flex-direction: column;
	padding: 15px;
	margin-top: 35px;
	color: #fff;
	width: 100%;
	> button:first-child {
		@include gradient_button;
		max-width: 150px;
	}
}
.details_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 1000px;
	padding-top: 50px;
	padding-bottom: 50px;
	margin: 0 auto;
	margin-top: 25px;
	position: relative;
}
.actions_group {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	> svg {
		font-size: 45px;
		color: #fff;
		background-color: darken($pink-color, 15%);
		box-shadow: 0 0 5px 5px darken($pink-color, 15%);
		border-radius: 5px;
		padding: 5px;
		cursor: pointer;
		transition: all 0.4s ease-in-out;
		&:hover {
			background-color: $pink-color;
			box-shadow: 0 0 5px 5px $pink-color;
		}
	}
	> svg:last-child {
		margin-left: 25px;
		background-color: darken($blue-color, 15%);
		box-shadow: 0 0 5px 5px darken($blue-color, 15%);
		&:hover {
			background-color: $blue-color;
			box-shadow: 0 0 5px 5px $blue-color;
		}
	}
}
.title {
	@include gradient-text;
	font-size: 50px;
	margin-top: 25px;
	margin-bottom: 0;
}
.lower_container {
	display: flex;
	flex-direction: column;
	max-width: 1000px;
	margin: 0 auto;
	position: relative;
	> img {
		width: 100%;
		height: auto;
		//box-shadow: 5px 5px 5px $pink-color;
	}
}
.score_container {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	//background: $dark-blue-accent;
	color: #fff;
	opacity: 0.8;
	padding: 10px;
	width: 67px;
	border-radius: 50%;
	> p {
		margin: 0;
		width: 100%;
		font-weight: bold;
		text-align: center;
	}
	> p:first-child {
		text-align: center;
		font-size: 28px;
		border: 1px solid #fff;
		border-radius: 50%;
		padding: 5px;
		background-color: $dark-blue-accent;
		color: $blue-color;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
			rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	}
}
.card_grid {
	margin-top: 25px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	> div {
		background-color: #00232c;
		> div:first-child {
			> svg {
				font-size: 20px;
				@media (min-width: 500px) {
					font-size: 30px;
				}
			}
		}
		h1,
		p {
			margin: 0;
		}
	}
	@media (min-width: 550px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
.not_found {
	min-height: 100vh;
	padding: 15px;
	color: #fff;
	margin-top: 35px;
	> button:first-child {
		@include gradient_button;
		max-width: 150px;
	}
	> h1,
	h2 {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 50px;
		margin-top: 50px;
		@media (min-width: 400px) {
			font-size: 75px;
		}
		> svg {
			font-size: 50px;
			color: $pink-color;
			margin-right: 15px;
			@media (min-width: 400px) {
				font-size: 75px;
			}
		}
	}
	> h2 {
		margin-top: 0;
		font-size: 35px;
	}
}
