@import '../../scss/colors.scss';
@import '../../scss/mixins.scss';

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border: 1px solid $pink-color;
	border-radius: 5px;
	transition: all 0.4s ease-in-out;
	width: 100%;
	&:hover {
		transform: scale(1.04);
		background-color: #00232c;
	}
	> div:first-child {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		> svg {
			font-size: 45px;
			color: $pink-color;
			margin: 10px;
		}
	}
	> h1 {
		font-size: 22px;
		color: $blue-color;
		font-weight: bold;
		text-align: center;
	}
	> p:last-child {
		font-size: 16px;
		color: #eee;
		text-align: center;
		flex: 1;
	}
	> button {
		@include gradient-button;
	}
}
.container_noborders {
	@extend .container;
	border: none;
}
