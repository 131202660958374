@import '../../scss/colors.scss';
.dash_grid {
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	align-items: stretch;
	grid-gap: 25px;
	margin: 50px 15px;
	@media (min-width: 600px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
