@import '../../scss/colors.scss';

.search_container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	padding: 0 10px;
	> h2 {
		color: #eee;
		margin-bottom: 10px;
		text-align: left;
	}
	> div:nth-child(2) {
		display: flex;
		> input {
			border: none;
			width: 100%;
			font-size: 18px;
			padding: 10px;
			background-color: #eee;
			border-radius: 5px;
			&::placeholder {
				color: #707070;
			}
		}
		> button {
			display: flex;
			justify-content: center;
			align-items: center;
			background: none;
			border: none;
			outline: none;
			background-color: $pink-color;
			color: #eee;
			border-radius: 5px;
			cursor: pointer;
			margin-left: 5px;
			> svg {
				font-size: 25px;
				font-weight: bold;
			}
		}
	}
}
