@import '../../scss/colors.scss';

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	> div:nth-child(3) {
		margin-top: 25px;
		margin-bottom: 25px;
		> button {
			background: none;
			border: none;
			outline: none;
			font-family: 'Readex Pro', sans-serif;
			font-size: 18px;
			color: #fff;
			font-weight: 700;
			background-color: darken($green-color, 15%);
			box-shadow: 0 0 5px 5px darken($green-color, 15%);
			cursor: pointer;
			transition: all 0.4s ease-in-out;
			padding: 8px 15px;
			border-radius: 5px;
			&:hover {
				background-color: $green-color;
				box-shadow: 0 0 5px 5px $green-color;
			}
			&:disabled {
				cursor: not-allowed;
				opacity: 0.5;
			}
		}
		> button:last-child {
			margin-left: 35px;
			background-color: darken($red-color, 10%);
			box-shadow: 0 0 5px 5px darken($red-color, 10%);
			&:hover {
				background-color: $red-color;
				box-shadow: 0 0 5px 5px $red-color;
			}
		}
	}
}

h4 {
	> span {
		color: $blue-color;
	}
}
