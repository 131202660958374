$blue-text: #7aadff;

.header_container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	color: $blue-text;
	> ul {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		margin-right: 35px;
		> li {
			list-style: none;
			color: $blue-text;
			font-weight: 700;
			cursor: pointer;
			> a {
				text-decoration: none;
				color: $blue-text;
			}
		}
		> li:not(:last-child) {
			margin-right: 20px;
		}
	}
}
