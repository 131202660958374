@import '../../scss/mixins.scss';
@import '../../scss/colors.scss';

.grid {
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	align-items: stretch;
	grid-gap: 25px;
	padding: 10px;
	margin-top: 25px;
	@media (min-width: 650px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 15px;
	}
}
.pages {
	display: flex;
	justify-content: center;
	color: #eee;
}
.btn_group {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	> button:first-child {
		margin-right: 25px;
	}
	> button {
		@include gradient-button;
		width: 50px;
		> svg {
			font-weight: bold;
		}
	}
}
.no_bourbons_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	> div:first-child {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		> h1 {
			color: #fff;
			margin-bottom: 0;
		}
		> h3 {
			color: $blue-color;
			margin-bottom: 0;
		}
	}

	> ul {
		list-style: square;
		> li {
			color: $pink-color;
		}
	}
}
