@import '../../scss/colors.scss';

.modal_container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 95%;
	max-height: 95%;
	z-index: 4;
	background-color: $dark-blue-accent;
	padding: 15px;
	border-radius: 5px;
	overflow: auto;
	> svg {
		display: flex;
		margin-left: auto;
		font-size: 40px;
		font-weight: bold;
		color: $pink-color;
		cursor: pointer;
	}
	@media (min-width: 800px) {
		max-width: 700px;
	}
}
.overlay {
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.85;
}
