.card_grid {
	margin-top: 50px;
	margin-bottom: 50px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	> div {
		background-color: #00232c;
		> div:first-child {
			> svg {
				font-size: 20px;
				@media (min-width: 500px) {
					font-size: 30px;
				}
			}
		}
		h1,
		p {
			margin: 0;
		}
	}
	@media (min-width: 550px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
